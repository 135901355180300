import './main.scss';

// Play video when button is clicked
let btnPlay = document.querySelector('.video-play-button');
let bg = document.getElementById("popup-bg");
let btnClosePopup = document.getElementById("close-popup");
let video = document.getElementById("video");
let popup = document.getElementById("popup-container");

// This will run when the .click-me element is clicked
btnPlay.addEventListener('click', function (e) {
    popup.classList.add('popup-open');
    document.body.classList.add('body-lock');

    setTimeout(function () {
        video.play();
    }, 400);
});

//Close popup
bg.addEventListener('click', function (e) {
    popup.classList.remove('popup-open');
    document.body.classList.remove('body-lock');
    video.pause();

    setTimeout(function () {
        video.currentTime = 0;
        video.load();
    }, 400);
});

btnClosePopup.addEventListener('click', function (e) {
    popup.classList.remove('popup-open');
    document.body.classList.remove('body-lock');
    video.pause();

    setTimeout(function () {
        video.currentTime = 0;
        video.load();
    }, 400);
});
